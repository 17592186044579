import info from "@/api/info";

const state = {
  assets: JSON.parse(localStorage.getItem("assets")) || [],
  onlineUsers: [],
  isConnected: false,
  lastBlock: JSON.parse(localStorage.getItem("lastBlock")) || null,
};

const getters = {
  getCurrency: (state) => state.assets,
  onlineUsers: (state) => state.onlineUsers.length - 1,
  isConnected: (state) => state.isConnected,
  getOnBlock: (state) => state.lastBlock,
};

const actions = {
  async fetchAssets({ commit }) {
    const response = await info.assets();
    localStorage.setItem("assets", JSON.stringify(response.data));
    commit("fetchAssets", response.data);
  },
  setConnect({ commit }, data) {
    commit("setConnect", data);
  },
  setBlock({ commit }, data) {
    localStorage.setItem("lastBlock", JSON.stringify(data));

    // commit("lastBlock", data);
  },
  async setOnlines({ commit }) {
    const response = await info.onlineusers();
    state.onlineUsers = [];
    for (var socketId in response.data) {
      commit("UsersConnected", socketId);
    }
  },
  UsersConnected({ commit }, socketId) {
    commit("UsersConnected", socketId);
  },
  UsersDisconnected({ commit }, socketId) {
    commit("UsersDisconnected", socketId);
  },
};

const mutations = {
  fetchAssets: (state, assets) => (state.assets = assets),
  setBlock: (state, lastBlock) => (state.lastBlock = lastBlock),
  setOnlines: (state, onlineUsers) => (state.onlineUsers = onlineUsers),
  setConnect: (state, isConnected) => (state.isConnected = isConnected),
  UsersConnected(state, socketId) {
    state.onlineUsers.push(socketId);
  },
  UsersDisconnected(state, socketId) {
    var index = state.onlineUsers.indexOf(socketId);
    if (index >= 0) {
      state.onlineUsers.splice(index, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
