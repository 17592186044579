import map from "@/api/map";

const state = {
  station: JSON.parse(localStorage.getItem("station")) || [],
};

const getters = {
  getStation: (state) => state.station,
};

const actions = {
  async fetchStation({ commit }) {
    const response = await map.station();
    localStorage.setItem("station", JSON.stringify(response.data));
    commit("fetchStation", response.data);
  },
};

const mutations = {
  fetchStation: (state, station) => (state.station = station),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
