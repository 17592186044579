import api from "@/api/index";

export default {
  station(credentials) {
    return api().get("station", credentials);
  },

  StationCraete(credentials) {
    return api().post("station/create", credentials);
  },

  StationDelete(credentials) {
    return api().post("station/delete", credentials);
  },
};
