import api from "@/api/index";

export default {
  onlineusers(credentials) {
    return api().get("onlineusers", credentials);
  },
  assets(credentials) {
    return api().get("assets", credentials);
  },
  txrelation(credentials) {
    return api().post("transparent/txrelation", credentials);
  },
  usertx(credentials) {
    return api().post("transparent/usertx", credentials);
  },
  usertxbet(credentials) {
    return api().post("transparent/usertxbet", credentials);
  },
  fundsummary(credentials) {
    return api().get("transparent/fundsummary", credentials);
  },
  transactions(credentials) {
    return api().post("transparent/transactions", credentials);
  },
  user(credentials) {
    return api().post("transparent/user", credentials);
  },
  ranking(credentials) {
    return api().post("transparent/ranking", credentials);
  },
  allwithdrawal(credentials) {
    return api().post("transparent/allwithdrawal", credentials);
  },
  alldeposit(credentials) {
    return api().post("transparent/alldeposit", credentials);
  },
  users(credentials) {
    return api().post("transparent/users", credentials);
  },
  livexbet(credentials) {
    return api().get("transparent/livexbet", credentials);
  },
  livexhash(credentials) {
    return api().get("transparent/livexhash", credentials);
  },
};
