import liquidity from "@/api/liquidity";

const state = {
  market: JSON.parse(localStorage.getItem("Market")) || null,
};

const getters = {
  getMarket: (state) => state.market,
};

const actions = {
  async fetchMarket({ commit }) {
    await liquidity.market().then((response) => {
      localStorage.setItem("Market", JSON.stringify(response.data));
      commit("fetchMarket", response.data);
    });
  },
  setMarket({ commit }, market) {
    localStorage.setItem("Market", JSON.stringify(market));
    commit("setMarket", market);
  },
};

const mutations = {
  fetchMarket: (state, market) => (state.market = market),
  setMarket: (state, market) => {
    state.market = market;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
