import api from "@/api/index";

export default {
  market(credentials) {
    return api().get("/liquidity", credentials);
  },
  create(credentials) {
    return api().get("/liquidity/create", credentials);
  },
  add(credentials) {
    return api().get("/liquidity/add", credentials);
  },
  remove(credentials) {
    return api().post("/liquidity/remove", credentials);
  },
  swapbuy(credentials) {
    return api().post("/liquidity/swapbuy", credentials);
  },
  swapsell(credentials) {
    return api().post("/liquidity/swapsell", credentials);
  },
};
