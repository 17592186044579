const state = {
  token: JSON.parse(localStorage.getItem("Token")) || null,
  user: JSON.parse(localStorage.getItem("User")) || null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  getToken: (state) => state.token,
  getUser: (state) => state.user,
};

const actions = {
  setToken({ commit }, token) {
    commit("setToken", token);
  },
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  logout() {
    state.token = null;
    state.user = null;
    localStorage.removeItem("Token");
    localStorage.removeItem("User");
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem("Token", JSON.stringify(token));
  },
  setUser(state, user) {
    state.user = user;
    localStorage.setItem("User", JSON.stringify(user));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
