import user from "@/api/user";

const state = {
  balance: JSON.parse(localStorage.getItem("Balance")) || {},
};

const getters = {
  getBalance: (state) => state.balance,
};

const actions = {
  async fetchBalance({ commit }) {
    await user.balance().then((response) => {
      localStorage.setItem("Balance", JSON.stringify(response.data));
      commit("fetchBalance", response.data);
    });
  },
  setBalance({ commit }, data) {
    localStorage.setItem("Balance", JSON.stringify(data));
    commit("setBalance", data);
  },
};

const mutations = {
  fetchBalance: (state, balance) => (state.balance = balance),
  setBalance: (state, balance) => (state.balance = balance),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
