import { createRouter, createWebHistory } from "vue-router";
import nativeToast from "native-toast";
import store from "../store";

const routes = [
  {
    path: "/funds",
    component: () => import("../views/auth/funds"),
    meta: { requiresAuth: true },
  },

  // {
  //   path: "/earn",
  //   component: () => import("../views/public/earn.vue"),
  // },
  {
    path: "/search/:sort",
    component: () => import("../views/public/search.vue"),
  },
  {
    path: "/search",
    component: () => import("../views/public/search.vue"),
  },
  {
    path: "/account/:address",
    component: () => import("../views/auth/account"),
  },
  {
    path: "/editaccount/:address",
    component: () => import("../views/auth/editAccount"),
    meta: { requiresAuth: true },
  },

  {
    path: "/admin",
    component: () => import("../views/auth/admin/AdminList.vue"),
    meta: { requiresAuth: true },
  },

  // {
  //   path: "/stakeLP/:contract_address",
  //   component: () => import("../views/auth/StakeLP.vue"),
  //   meta: { requiresAuth: true },
  // },

  {
    path: "/balance/:currency",
    component: () => import("../views/auth/balance.vue"),
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/kyc/:address",
  //   component: () => import("../views/auth/accountKYC.vue"),
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/imgRecovery",
    component: () => import("../views/auth/selectKYC.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/imgRecovery/:_id",
    component: () => import("../views/auth/selectKYC.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/buyCredit",
    component: () => import("../views/auth/buyCredit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/genAI",
    component: () => import("../views/auth/genAI.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/genImagetoImage",
    component: () => import("../views/auth/genImagetoImage.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/detailProduct/:_id/:img_select",
    component: () => import("../views/auth/detailProduct.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/models",
    component: () => import("../views/public/models.vue"),
  },

  // {
  //   path: "/addphone/:address",
  //   component: () => import("../views/auth/addPhone.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/casino",
  //   component: () => import("../views/auth/casino"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/one_hash",
  //   component: () => import("../views/auth/bet/one_hash.vue"),
  //   meta: { requiresAuth: true },
  // },

  {
    path: "/sell",
    component: () => import("../views/auth/sell/index.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/publicFree",
    component: () => import("../views/auth/sell/publicFree.vue"),
  },

  // {
  //   path: "/bet",
  //   component: () => import("../views/auth/bet/bet.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/two_hash",
  //   component: () => import("../views/auth/bet/two_hash.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/last_hash",
  //   component: () => import("../views/auth/bet/last_hash.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/collections",
  //   component: () => import("../views/auth/myCollections.vue"),
  //   meta: { requiresAuth: true },
  // },

  {
    path: "/",
    component: () => import("../views/public/home.vue"),
  },
  {
    path: "/prompt/:_id",
    component: () => import("../views/public/prompt_detail.vue"),
  },
  {
    path: "/promptFree/:_id",
    component: () => import("../views/public/prompt_free_detail.vue"),
  },
  {
    path: "/promptAdmin/:_id",
    component: () => import("../views/auth/admin/AdminDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pay/:_id",
    component: () => import("../views/public/pay.vue"),
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/refer",
  //   component: () => import("../views/public/refer.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/r/:ref",
  //   component: () => import("../views/public/home.vue"),
  // },
  {
    path: "/model/:model",
    component: () => import("../views/public/detail.vue"),
  },
  {
    path: "/product/:id",
    component: () => import("../views/public/product_detail.vue"),
  },

  {
    path: "/booking/:model",
    component: () => import("../views/public/booking.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/public/pagenotfound.vue"),
  },
  {
    path: "/user/:address",
    component: () => import("../views/public/user.vue"),
  },
  {
    path: "/txrelation/:tx_id",
    component: () => import("../views/public/txrelation.vue"),
  },
  {
    path: "/transactions/:address",
    component: () => import("../views/public/transactions.vue"),
  },
  {
    path: "/deposit/:address",
    component: () => import("../views/public/deposit.vue"),
  },
  {
    path: "/faq",
    component: () => import("../views/public/faq.vue"),
  },
  {
    path: "/privacy",
    component: () => import("../views/public/Privacy.vue"),
  },
  {
    path: "/TermOfService",
    component: () => import("../views/public/TermOfService.vue"),
  },

  {
    path: "/withdraw/:address",
    component: () => import("../views/public/withdraw.vue"),
  },

  {
    path: "/ranking/:currency",
    component: () => import("../views/public/ranking.vue"),
  },
  {
    path: "/lastesttransactions",
    component: () => import("../views/public/lastesttransactions.vue"),
  },
  {
    path: "/alldeposit",
    component: () => import("../views/public/alldeposit.vue"),
  },
  {
    path: "/allwithdrawal",
    component: () => import("../views/public/allwithdrawal.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/");
    nativeToast({
      message: "Please Connect Metamask.",
      position: "top",
      timeout: 3000,
      type: "error",
    });
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
